<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Actualizacion Egresados" collection="actualizacion_egresados" templateTable="TableActualizacionEgresados" templateTableModal="TableActualizacionEgresadosModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        DNIoPasaporte_c: 'Pasaporte',
        RUToRUTProvisionalDeContacto_c: 'RUT',
        PrimaryContactPersonFirstName: 'Nombre',
        PrimaryContactPersonLastName: 'Apellido paterno',
        PrimaryContactEmailAddress: 'E-mail Personal',
        PrimaryPhoneCountryCode: 'Código País',
        PrimaryPhoneAreaCode: 'Código Área',
        PrimaryPhoneNumber: 'Número celular',
        TipoDeTelefono_c: 'Tipo de teléfono',
        FormularioDeOrigen_c: 'Formulario de origen',
        Institucion_c: 'Institución',
        situacion_laboral: 'Situación laboral',
        empresa: 'Empresa',
        cargo: 'Cargo',
        antiguedad: 'Antigüedad',
        empresa_direccion: 'Dirección de la empresa',
        empresa_region: 'Región de la empresa',
        empresa_comuna: 'Comuna de la empresa',
        empresa_area_code: 'Código teléfono empresa',
        empresa_country_code: 'Código país empresa',
        empresa_phone_number: 'Número de teléfono empresa',
        empresa_email: 'E-mail empresa'
      },
      query: {
        collection: 'formulario_retiros',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
